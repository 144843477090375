@import '/src/App.css';

.companyRegistrationMainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    margin: 0;
    background-image: url('../../assets/images/fondo_general_naranja.png');
    background-size: cover;
    overflow: hidden;
}

.companyRegistrationSubContainer1 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50vw;
    padding: 4vh 3vw;
    justify-content: space-between;
}

.companyRegistrationImgPragraphContainer {
    display: flex;
    flex-direction: column;
}

.companyRegistrationSubContainer1Logo {
    height: 10vh;
    margin-top: 8vh;
    align-self: flex-start;
}

.companyRegistrationContainer1Paragraph {
    font-family: var(--quicksand-bold);
    color: var(--white);
    font-size: 2vw;
    margin-top: 4vh;
}

.companyRegistrationContainer1H2 {
    width: 40vw;
    font-family: var(--quicksand-bold);
    font-size: 3vw;
    color: var(--white);
}

.compayRegistrationReturnParagraph,
.compayRegistrationReturnParagraph:hover {
    font-family: var(--poppins-medium);
    font-size: 3vh;
    color: var(--white);
    text-decoration: none;
}

.companyRegistrationSubContainer2 {
    background-color: var(--white);
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50vw;
    padding: 5vh 6vw;
    align-items: flex-end;
    overflow: auto;
}

.companyRegistrationLogInLink1 {
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    text-decoration: none;
    font-size: 1vw;
}

.companyRegistrationInputs {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    gap: 1vh;
}

.companyRegistrationInputs small {
    color: red;
    font-size: 1vw;
    font-family: var(--poppins-light);
}

.showPassButtonInicioSesion {
    display: flex;
    width: 2vw;
    position: absolute;
    top: 18px;
    right: 10px;
}

.companyRegistrationContainer2FormPassword {
    position: relative;
    margin: 0;
}

.companyRegistrationLogInLink2 {
    font-family: var(--poppins-bold);
    color: var(--light-purple);
    font-size: 1vw;
}

.companyRegistrationContainer2Form {
    border: solid 2px var(--dark-grey);
    border-radius: 0.4vw;
    width: 38vw;
    min-height: 0 !important;
    height: 6vh !important;
    padding: 1vh !important;
    font-size: 1.2vw;
    font-family: var(--poppins-light);
}

.companyRegistrationContainer2Form:disabled {
    cursor: not-allowed;
}

.companyRegistrationInputButtonContainer {
    width: 38vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.companyRegistrationFileCustomButton {
    width: fit-content;
    padding: 1vh 2vw;
    background-color: var(--orange);
    color: var(--white);
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--poppins-light);
    font-size: 1vw;
}

.companyRegistrationfileName {
    font-family: var(--poppins-light);
    color: var(--dark-grey3);
    font-size: 0.9vw;
    margin-left: 1vw;
    /* border-bottom: 1px solid black;
    width: 25vw; */
}

.hidden {
    display: none;
}

.companyRegistrationContinueButton {
    font-family: var(--poppins-medium);
    font-size: 1.6vw;
    border-radius: 13px;
    padding: 1vh 0;
    margin-top: 7vh;
    margin-bottom: 3vh;
    margin-left: 0;
    margin-right: 0;
    background-color: var(--orange);
    color: var(--white);
    border: 100px;
    width: 100%;
}

.companyRegistrationContinueButton:disabled{
    opacity: .4;
}

.fileName{
    margin-left: .7vw;
    font-family: var(--poppins-light);
    font-size: 1vw;
    color: var(--dark-grey7);
}

.fileNameEmpty{
    margin-left: .7vw;
    font-family: var(--poppins-light);
    font-size: 1vw;
    color: var(--dark-grey3);
}

.form-floating label {
    font-family: var(--poppins-light);
    font-size: 1vw;
    color: var(--dark-grey3);
}

.employeeRightFormat {
    display: none !important;
}

.employeeIncorrectFormat {
    color: red !important;
    font-size: 0.8vw !important;
    font-family: var(--poppins-light) !important;
}